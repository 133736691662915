import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, setDoc, updateDoc, onSnapshot, getDoc, addDoc } from 'firebase/firestore';
import { firestore } from './firebase';
import { useNavigate } from 'react-router-dom';
// import styled, { keyframes } from 'styled-components';
import styled from 'styled-components';
// import { arrayUnion } from 'firebase/firestore';
import axios from 'axios'; // Add this import
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import QRCode from 'qrcode.react';
import Timer from './Timer';
import gametime from './gametime.png'; // Assuming you have star1.png in the same folder
import confetti from 'canvas-confetti';


const FormGroup = styled.div`
  margin-bottom: 20px;
  width: 100%;
  max-width: 300px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;

const NumberInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;

// const SpinnerIcon = styled.div`
//   display: inline-block;
//   width: 20px;
//   height: 20px;
//   border: 3px solid #f3f3f3;
//   border-top: 3px solid #3498db;
//   border-radius: 50%;
//   animation: ${spin} 1s linear infinite;
//   margin-right: 10px;
// `;
const ShareSection = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// const peachColors = [
//   '#FFDAB9', '#FFE4B5', '#FFEFD5', '#FFE4E1', '#FFF0F5',
//   '#FAF0E6', '#FFF5EE', '#FFEBCD', '#FFE4C4', '#FAEBD7'
// ];

const TopicButton = styled.button`
  margin: 0 15px;
  padding: 15px 25px;
  white-space: nowrap;
  background-color: #ffffff;
  border: 2px solid #333333;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    background-color: #f0f0f0;
  }
`;


const QuestionText = styled.p`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`;

const ShareButton = styled.button`
  position: relative;
  display: inline-block;
  padding: 12px 40px 12px 20px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: #4CAF50;
  background-color: white;
  border: 2px solid #4CAF50;
  cursor: pointer;
  margin-top: 15px;
  transition: all 0.2s ease;

  /* Simplified comic book style */
  border-radius: 25px;
  box-shadow: 3px 3px 0 #000;

  /* Simplified text effect */
  text-shadow: 1px 1px 0 #fff;

  /* Hover effect */
  &:hover {
    transform: translateX(3px);
    box-shadow: 5px 5px 0 #000;
  }

  /* Active effect */
  &:active {
    transform: translateX(1px);
    box-shadow: 1px 1px 0 #000;
  }

  /* Arrow icon */
  &::after {
    content: "➜";
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    color: #4CAF50;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px 35px 10px 15px;
  }
`;

const AllQAContainer = styled.div`
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
`;

const AllQATitle = styled.h3`
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #4CAF50;
  padding-bottom: 10px;
`;

const QuestionCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
`;

const QuestionTitle = styled.h4`
  font-size: 20px;
  color: #2196F3;
  margin-bottom: 10px;
`;

const ChoicesText = styled.p`
  font-size: 16px;
  color: #555;
  margin-bottom: 8px;
`;

const StyledInput = styled.input`
  width: 300px;
  padding: 10px;
  font-size: 16px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const RemoveQuestionButton = styled.button`
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
`;

const LeaderboardContainer = styled.div`
  background-color: #f8f9fa;
  border-radius: 15px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
  max-width: 400px;
  width: 100%;
`;

const LeaderboardTitle = styled.h3`
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const LeaderboardList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const LeaderboardItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 12px 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-2px);
  }
`;

const LoadingSpinner = styled.div`
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const PlayerName = styled.span`
  font-weight: bold;
  font-size: 16px;
`;

const PlayerScore = styled.span`
  font-weight: bold;
  font-size: 18px;
  color: #4CAF50;
`;

const InlineSpinner = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-top: 2px solid #4CAF50;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 10px;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const PlayerRank = styled.span`
  font-weight: bold;
  font-size: 18px;
  color: #ff9800;
  margin-right: 10px;
`;
const AnswerButton = styled.button`
  position: relative;
  display: inline-block;
  padding: 12px 20px;
  margin: 10px;
  font-size: 18px;
  font-weight: bold;
  font-family: Arial, sans-serif; // Changed font family
  text-transform: uppercase;
  color: #fff;
  background-color: #4CAF50;
  border: 2px solid #000;
  cursor: pointer;
  transition: all 0.2s ease;

  /* Simplified comic book style */
  border-radius: 8px;
  box-shadow: 3px 3px 0 #000;

  /* Removed text shadow for better legibility */

  &:hover {
    transform: translateY(-3px);
    box-shadow: 5px 5px 0 #000;
    background-color: #45a049;
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 1px 1px 0 #000;
  }

  &:disabled {
    background-color: #cccccc;
    color: #fff !important;
    cursor: not-allowed;
    transform: none;
    box-shadow: 2px 2px 0 #000;
    opacity: 0.7;
  }

  &.correct {
    background-color: #4CAF50;
    color: #fff !important;
  }

  &.incorrect {
    background-color: #f44336;
    color: #fff !important;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 10px 16px;
    margin: 8px;
  }
`;

const PartyGameContainer = styled.div`
  overflow-x: visible; // This will prevent horizontal scrolling on the page
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;
const ReturnButton = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  font-family: Arial, sans-serif; // Changed font family
  text-transform: uppercase;
  color: #000;
  background-color: white;
  border: 2px solid #000;
  cursor: pointer;
  transition: all 0.2s ease;

  /* Simplified comic book style */
  border-radius: 8px;
  box-shadow: 3px 3px 0 #000;

  /* Removed text shadow for better legibility */

  &:hover {
    transform: translateY(-2px);
    box-shadow: 5px 5px 0 #000;
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 1px 1px 0 #000;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 6px 12px;
  }
`;

const GameButton = styled.button`
  position: relative;
  display: inline-block;
  padding: 15px 30px;
  margin: 15px;
  font-size: 24px;
  font-weight: bold;
  font-family: Arial, sans-serif; // Changed font family
  text-transform: uppercase;
  color: #000;
  width: 280px; // Add this line to make all buttons the same width

  background-color: #fff;
  border: 3px solid #000;
  cursor: pointer;
  transition: all 0.2s ease;

  /* Simplified comic book style */
  border-radius: 12px;
  box-shadow: 4px 4px 0 #000;

  /* Removed text shadow for better legibility */

  &:hover:not(:disabled) {
    transform: translateY(-4px);
    box-shadow: 6px 6px 0 #000;
    background-color: #f0f0f0;
  }

  &:active:not(:disabled) {
    transform: translateY(2px);
    box-shadow: 2px 2px 0 #000;
    background-color: #e0e0e0;
  }

  &:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
    box-shadow: none;
  }

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 10px 20px;
    margin: 8px;
  }
`;

const TriviaGameButton = styled(GameButton)`
  color: #fff;
  background-color: #007bff; // Blue background

  &:hover:not(:disabled) {
    background-color: #0069d9; // Darker blue on hover
  }

  &:active:not(:disabled) {
    background-color: #0056b3; // Even darker blue on active
  }
`;

const CreativeGameButton = styled(GameButton)`
  color: #fff;
  background-color: #dc3545; // Red background

  &:hover:not(:disabled) {
    background-color: #c82333; // Darker red on hover
  }

  &:active:not(:disabled) {
    background-color: #bd2130; // Even darker red on active
  }
`;

const CorrectAnswerText = styled.h3`
  font-size: 22px;
  color: #4CAF50;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  padding: 10px;
  background-color: #e8f5e9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const ExplanationText = styled.p`
  font-size: 18px;
  color: #333;
  line-height: 1.6;
  margin-top: 15px;
  padding: 15px;
  background-color: #f5f5f5;
  border-left: 5px solid #4CAF50;
  border-radius: 4px;
`;


const SampleTopics = [
  "The science of charisma ✨",

  "Fascinating Animal Behaviors 🐾",
  "How do Air Conditioning Systems work? 🌬️",
  "Optimizing microbiome gut health 🥗",
  "The Science of Sailboats ⛵️",

  "How neural networks work 🧠",

  "What inspired the lyrics of the greatest hit songs 🎶",
" Fun Facts About the Wonders of the World 🌎",
"Iconic Film and TV Moments 🎬",

  "Space Exploration Milestones 🚀"

];
const GlidingTopics = ({ topics, onTopicSelect }) => {
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPosition((prevPosition) => (prevPosition - 1) % (topics.length * 250));
    }, 50);

    return () => clearInterval(interval);
  }, [topics.length]);

  return (
    <div style={{ overflow: 'hidden', width: '100%', height: '60px', position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          left: `${position}px`,
          transition: 'left 0.05s linear',
          height: '100%',
          alignItems: 'center',
          width: 'max-content',  // Change from fixed width to max-content
        }}
      >
        {[...topics, ...topics].map((topic, index) => (
          <TopicButton 
            key={index} 
            onClick={() => onTopicSelect(topic)}
            index={index}
          >
            {topic}
          </TopicButton>
        ))}
      </div>
    </div>
  );
};
const PartyGame = ({ isHost: hostProp, isGuest }) => {

  const [showConfetti, setShowConfetti] = useState(false);


  const [gameState, setGameState] = useState(null);
  const [isHost, setIsHost] = useState(hostProp);
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(true);
  const [showCreateGame, setShowCreateGame] = useState(false);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(true);
  const [playersWhoAnswered, setPlayersWhoAnswered] = useState([]);
  const [userSubscriptionPlan, setUserSubscriptionPlan] = useState('free');
  const [showManualCreation, setShowManualCreation] = useState(false);
  const [manualQuestions, setManualQuestions] = useState([{ question: '', choices: ['', '', '', ''], correctAnswer: '' }]);
  const [showJoinGame, setShowJoinGame] = useState(false);
  const [gameTopic, setGameTopic] = useState('');
  const [gameDifficulty, setGameDifficulty] = useState('easy');
  const [numQuestions, setNumQuestions] = useState(5);
  const [generatingGame, setGeneratingGame] = useState(false);
  const [inviteCode, setInviteCode] = useState('');
  const [allQuestionsAndAnswers, setAllQuestionsAndAnswers] = useState([]);
  const [showGameTypeSelection, setShowGameTypeSelection] = useState(false);
  const [showJoinTrivia, setShowJoinTrivia] = useState(false);
  const [showJoinCreative, setShowJoinCreative] = useState(false);
  const navigate = useNavigate();
  // const [isLoadingGameState, setIsLoadingGameState] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [allPlayersAnswered, setAllPlayersAnswered] = useState(false);
  const [showGameButtons, setShowGameButtons] = useState(false);

  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const [gameFinished, setGameFinished] = useState(false);

  const [timerEnabled, setTimerEnabled] = useState(false);
const [timerDuration, setTimerDuration] = useState(10);
// const [timeRemaining, setTimeRemaining] = useState(null);

  const [nickname, setNickname] = useState('');
  const [waitingForPlayers, setWaitingForPlayers] = useState(true);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [leaderboard, setLeaderboard] = useState([]);
  const [players, setPlayers] = useState([]);
  // const [hasValidSubscription, setHasValidSubscription] = useState(false);
  //const [questionImages, setQuestionImages] = useState({});
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  const [currentTimerTime, setCurrentTimerTime] = useState(timerDuration);


  //  const [showCreativeGame, setShowCreativeGame] = useState(false);

   const handleStartCreativeGame = () => {
    navigate('/creativegame');
  };

  useEffect(() => {
    if (showConfetti) {
      const timer = setTimeout(() => {
        setShowConfetti(false);
      }, 3000); // Reset after 3 seconds
  
      return () => clearTimeout(timer);
    }
  }, [showConfetti]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          const userRef = doc(firestore, 'users', currentUser.uid);
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserSubscriptionPlan(userData.subscriptionPlan || 'free');
            console.log("Set subscription plan to:", userData.subscriptionPlan || 'free');
          } else {
            console.log("User document doesn't exist");
            setUserSubscriptionPlan('free');
          }
        } catch (error) {
          console.error("Error fetching user subscription:", error);
          setUserSubscriptionPlan('free');
        }
      } else {
        console.log("No user signed in");
        setUserSubscriptionPlan('free');
      }
    });
  
    return () => unsubscribe();
  }, []);

 // Update the useEffect hook to listen for changes in the isGenerating field:
 useEffect(() => {
  if (inviteCode) {
    const gameRef = doc(firestore, 'publicGames', inviteCode);
    const playersRef = collection(gameRef, 'players');
    
    const unsubscribeGame = onSnapshot(gameRef, (snapshot) => {
      if (snapshot.exists()) {
        const gameData = snapshot.data();
        setAllPlayersAnswered(gameData.playersWhoAnswered?.length === players.length);

        setGameState(gameData);
        setGeneratingGame(gameData.isGenerating);
        setCurrentQuestionIndex(gameData.currentQuestionIndex || 0);
        setTimerEnabled(gameData.timerEnabled || false);
        setTimerDuration(gameData.timerDuration || 10);
        setPlayersWhoAnswered(gameData.playersWhoAnswered || []);
        setSelectedAnswer(null);
        setGameFinished(gameData.gameFinished || false);
        setIsTimerRunning(gameData.isTimerRunning || false); // Add this line
        setCurrentTimerTime(gameData.currentTimerTime || timerDuration); // Add this line


        if (gameData.questions && Array.isArray(gameData.questions)) {
          setQuestions(gameData.questions);
          setAllQuestionsAndAnswers(gameData.questions);
          setIsLoadingQuestions(false);
        } else {
          setIsLoadingQuestions(true);
        }

        if (gameData.gameStarted && waitingForPlayers) {
          setWaitingForPlayers(false);
        }
      } else {
        setGameState(null);
        setPlayers([]);
        setQuestions([]);
        setIsLoadingQuestions(true);
      }
    });

    const unsubscribePlayers = onSnapshot(playersRef, (snapshot) => {
      const playersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPlayers(playersData);
      setLeaderboard(playersData.sort((a, b) => b.score - a.score));
    });

    return () => {
      unsubscribeGame();
      unsubscribePlayers();
    };
  }
}, [inviteCode, waitingForPlayers, players.length, timerDuration]);


const handleCreateNewGame = () => {
  setShowWelcomeScreen(false);
  setShowCreateGame(true);
  setGameFinished(false);
  setInviteCode('');
  setQuestions([]);
  setCurrentQuestionIndex(0);
  setPlayers([]);
  setLeaderboard([]);
};

const handleJoinNewGame = () => {
  setShowWelcomeScreen(false);
  setShowJoinGame(true);
  setGameFinished(false);
  setInviteCode('');
  setQuestions([]);
  setCurrentQuestionIndex(0);
  setPlayers([]);
  setLeaderboard([]);
};

const handleJoinGameClick = () => {
  setShowGameButtons(!showGameButtons);
};


const copyToClipboard = (text) => {
  if (navigator.clipboard && window.isSecureContext) {
    // For modern browsers
    return navigator.clipboard.writeText(text);
  } else {
    // Fallback for older browsers
    let textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((resolve, reject) => {
      document.execCommand('copy') ? resolve() : reject();
      textArea.remove();
    });
  }
};

// const extractKeywords = (question) => {
//   // Remove common words and keep only important ones
//   const commonWords = ['a', 'an', 'the', 'in', 'on', 'at', 'to', 'for', 'of', 'with', 'by', 'from', 'up', 'about', 'into', 'over', 'after'];
//   const words = question.toLowerCase().split(' ');
//   const keywords = words.filter(word => !commonWords.includes(word) && word.length > 2);
//   return keywords.slice(0, 3).join(' '); // Return top 3 keywords
// };
// const fetchImagesForQuestions = async (questions) => {
//   const imagePromises = questions.map(async (question) => {
//     try {
//       const searchQuery = extractKeywords(question.question);
//       const result = await axios.get('https://us-central1-aisupertutor.cloudfunctions.net/getPixabayImage', { 
//         params: { query: searchQuery }
//       });
//       return { [question.question]: result.data.imageUrl };
//     } catch (error) {
//       console.error('Error fetching image:', error);
//       return { [question.question]: null };
//     }
//   });

//   const images = await Promise.all(imagePromises);
//   const imageObject = Object.assign({}, ...images);
//   setQuestionImages(imageObject);
// };

const handleAddQuestion = () => {
  setManualQuestions([...manualQuestions, { question: '', choices: ['', '', '', ''], correctAnswer: '' }]);
};

const handleQuestionChange = (index, field, value) => {
  const updatedQuestions = [...manualQuestions];
  if (field === 'choice') {
    updatedQuestions[index].choices[value.index] = value.choice;
  } else if (field === 'correctAnswer') {
    updatedQuestions[index].correctAnswer = parseInt(value);
  } else {
    updatedQuestions[index][field] = value;
  }
  setManualQuestions(updatedQuestions);
};

const handleManualCreation = () => {
  setShowWelcomeScreen(false);
  setShowCreateGame(false);
  setShowJoinGame(false);
  setShowManualCreation(true);
};

const handleManualGameCreation = async () => {
  if (manualQuestions.some(q => !q.question || q.choices.some(c => !c) || !q.correctAnswer)) {
    alert('Please fill out all fields for each question.');
    return;
  }

  const formattedQuestions = manualQuestions.map(q => ({
    ...q,
    correctAnswer: q.choices[q.correctAnswer - 1]
  }));

  const inviteCode = generateNumericCode(4);
  setInviteCode(inviteCode);
  setShowCreateGame(false);
  setWaitingForPlayers(true);
  setIsHost(true);

  const auth = getAuth();
  const user = auth.currentUser;
  const userDoc = await getDoc(doc(firestore, 'users', user.uid));
  const userData = userDoc.data();
  const firstName = userData.name ? userData.name.split(" ")[0] : 'User';
  const hostNickname = `${firstName} (Host)`;
  setNickname(hostNickname);

  const gameRef = doc(firestore, 'publicGames', inviteCode);
  await setDoc(gameRef, {
    topic: gameTopic,
    difficulty: gameDifficulty,
    numQuestions: manualQuestions.length,
    currentQuestionIndex: 0,
    createdAt: new Date().toISOString(),
    questions: formattedQuestions,

    isGenerating: false,
  });

  const playersRef = collection(gameRef, 'players');
  await addDoc(playersRef, { 
    nickname: hostNickname,
    score: 0,
    answer: '',
    isHost: true
  });

  setQuestions(manualQuestions);
  setShowManualCreation(false);
};

const handleJoinSuperTutor = () => {
  navigate('/');
};


const handleAnswerSelection = async (answer) => {
  if (playersWhoAnswered.includes(nickname)) {
    console.log('You have already answered this question');
    return;
  }

  setSelectedAnswer(answer);
  const gameRef = doc(firestore, 'publicGames', inviteCode);
  const playersRef = collection(gameRef, 'players');

  // Find the player document
  const playerSnapshot = await getDocs(query(playersRef, where('nickname', '==', nickname)));
  
  if (!playerSnapshot.empty) {
    const playerDoc = playerSnapshot.docs[0];
    const playerData = playerDoc.data();
    const isCorrect = answer === questions[currentQuestionIndex].correctAnswer;
    const newScore = isCorrect ? (playerData.score || 0) + 10 : playerData.score || 0;

    await updateDoc(doc(playersRef, playerDoc.id), { 
      answer,
      score: newScore
    });

      // Trigger confetti if the answer is correct
      if (isCorrect) {
        setShowConfetti(true);
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 }
        });
      }

    // Update game document
    const gameDoc = await getDoc(gameRef);
    const gameData = gameDoc.data();
    let currentPlayersWhoAnswered = gameData.playersWhoAnswered || [];
    currentPlayersWhoAnswered.push(nickname);

    await updateDoc(gameRef, {
      playersWhoAnswered: currentPlayersWhoAnswered
    });

    // Update local state
    setPlayersWhoAnswered(currentPlayersWhoAnswered);

    // Fetch updated players and update leaderboard
    const updatedPlayersSnapshot = await getDocs(playersRef);
    const updatedPlayers = updatedPlayersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setPlayers(updatedPlayers);
    setLeaderboard(updatedPlayers.sort((a, b) => b.score - a.score));

    // If all players have answered, set allPlayersAnswered to true
    if (updatedPlayers.length === currentPlayersWhoAnswered.length) {
      setAllPlayersAnswered(true);
      // Instead, update the players who answered without stopping the timer
  await updateDoc(gameRef, {
    playersWhoAnswered: currentPlayersWhoAnswered
  });


    }
  } else {
    console.error('Player not found in the game');
  }
};

const handleTopicSelect = (topic) => {
  setGameTopic(topic);
};

// Add this function near the top of your file, outside the component:
const generateNumericCode = (length) => {
  return Array.from({ length }, () => Math.floor(Math.random() * 10)).join('');
};

const generateUniqueInviteCode = async () => {
  let isUnique = false;
  let inviteCode;

  while (!isUnique) {
    inviteCode = generateNumericCode(5);
    const gameRef = doc(firestore, 'publicGames', inviteCode);
    const gameDoc = await getDoc(gameRef);
    
    if (!gameDoc.exists()) {
      isUnique = true;
    }
  }

  return inviteCode;
};

  const handleReturnClick = () => {
    navigate('/');
  };

  const handleCreateGame = () => {
    setShowWelcomeScreen(false);
    setShowCreateGame(true);
  };

  // const handleJoinGame = () => {
  //   setShowWelcomeScreen(false);
  //   setShowJoinGame(true);
  // };
  const handleJoinGame = () => {
    setShowWelcomeScreen(false);
    setShowJoinGame(true);
  };


  
  const handleJoinCreativeGame = () => {
    navigate('/creativegame?join=true');
  };

  const handleRemoveQuestion = (indexToRemove) => {
    setManualQuestions(manualQuestions.filter((_, index) => index !== indexToRemove));
  };

// Modify the handleGenerateGame function:
const handleGenerateGame = async () => {

    if (!gameTopic.trim()) {
    alert("Please enter or choose a topic before creating the game.");
    return;
  }


  const inviteCode = await generateUniqueInviteCode();
  setInviteCode(inviteCode);
  setShowCreateGame(false);
  setWaitingForPlayers(true);
  setIsHost(true);
  setGeneratingGame(true);

  // Get the current user
  const auth = getAuth();
  const user = auth.currentUser;
  // Fetch user data from Firestore
  const userDoc = await getDoc(doc(firestore, 'users', user.uid));
  const userData = userDoc.data();
  const firstName = userData.name ? userData.name.split(" ")[0] : 'User';
  const hostNickname = `${firstName} (Host)`;
  setNickname(hostNickname);

  const gameRef = doc(firestore, 'publicGames', inviteCode);
  await setDoc(gameRef, {
    topic: gameTopic,
    difficulty: gameDifficulty,
    numQuestions,
    currentQuestionIndex: 0,
    createdAt: new Date().toISOString(),
    questions: [],
    isGenerating: true,
    timerEnabled,
    timerDuration,


  });

   // Add the host to the players subcollection
   const playersRef = collection(gameRef, 'players');
   await addDoc(playersRef, { 
     nickname: hostNickname,
     score: 0,
     answer: '',
     isHost: true
   });
 
  try {
    await axios.post('https://us-central1-aisupertutor.cloudfunctions.net/generatePartyQuizGame2', {
      topic: gameTopic,
      inviteCode,
      difficulty: gameDifficulty,
      numQuestions,
    });

    // Update the game document to indicate generation is complete
    await updateDoc(gameRef, { isGenerating: false });
    setGeneratingGame(false);
  } catch (error) {
    console.error('Error generating quiz game:', error.response?.status, error.response?.data);
    alert('An error occurred while generating the quiz game. Please try again.');
    setGeneratingGame(false);
  }
};

const [showGameOptions, setShowGameOptions] = useState(false);
const handleCreateGameClick = () => {
  setShowGameOptions(true);
};
const handleJoinCreativeGameSubmit = async () => {
  const gameRef = doc(firestore, 'creativeGames', inviteCode);
  const gameDoc = await getDoc(gameRef);

  if (gameDoc.exists()) {
    const playersRef = collection(gameRef, 'players');
    await addDoc(playersRef, { 
      nickname, 
      score: 0,
    });

    setShowJoinCreative(false);
    setWaitingForPlayers(true);
    setIsHost(false);
    setNickname(nickname);
  } else {
    alert('Invalid invite code. Please enter a valid invite code.');
  }
};

const FinalSummary = ({ leaderboard, allQuestionsAndAnswers, isHost, userSubscriptionPlan, handleCreateNewGame, handleJoinNewGame, handleJoinSuperTutor }) => (
  <PartyGameContainer>
    <h2>Game Over</h2>
    <LeaderboardContainer>
      <LeaderboardTitle>Final Leaderboard</LeaderboardTitle>
      <LeaderboardList>
        {leaderboard.map((player, index) => (
          <LeaderboardItem key={index}>
            <div>
              <PlayerRank>{index + 1}.</PlayerRank>
              <PlayerName>{player.nickname}</PlayerName>
            </div>
            <PlayerScore>{player.score}</PlayerScore>
          </LeaderboardItem>
        ))}
      </LeaderboardList>
    </LeaderboardContainer>
    
    {isHost ? (
  <>
    <GameButton onClick={handleCreateNewGame}>Create a New Game</GameButton>
    <GameButton onClick={handleJoinNewGame}>Join a New Game</GameButton>
  </>
) : (
      <>
        <GameButton onClick={handleJoinNewGame}>Join a New Game</GameButton>
        {!isHost && userSubscriptionPlan === 'free' && (
          <GameButton onClick={handleJoinSuperTutor}>Join SuperTutor to Create Your Own Games</GameButton>
        )}
      </>
    )}
    
    <AllQAContainer>
      <AllQATitle>All Questions and Answers</AllQATitle>
      {allQuestionsAndAnswers.map((qa, index) => (
        <QuestionCard key={index}>
          <QuestionTitle>Question {index + 1}: {qa.question}</QuestionTitle>
          <ChoicesText>Choices: {qa.choices.join(', ')}</ChoicesText>
          <CorrectAnswerText>Correct Answer: {qa.correctAnswer}</CorrectAnswerText>
          <ExplanationText>Explanation: {qa.explanation}</ExplanationText>
        </QuestionCard>
      ))}
    </AllQAContainer>
  </PartyGameContainer>
);
  
const handleJoinGameSubmit = async () => {
  if (!inviteCode || !nickname) {
    alert('Please enter both invite code and nickname.');
    return;
  }

  const gameRef = doc(firestore, 'publicGames', inviteCode);
  try {
    const gameDoc = await getDoc(gameRef);

    if (gameDoc.exists()) {
      const playersRef = collection(gameRef, 'players');
      await addDoc(playersRef, { 
        nickname, 
        score: 0, 
        answer: null 
      });

      setShowJoinGame(false);
      setWaitingForPlayers(true);
      setIsHost(false);
    } else {
      alert('Invalid invite code. Please enter a valid invite code.');
    }
  } catch (error) {
    console.error('Error joining game:', error);
    alert('An error occurred while joining the game. Please try again.');
  }
};

  const handleStartGame = async () => {
    console.log('handleStartGame started');
    setWaitingForPlayers(false);
    setIsLoadingQuestions(true);
  
    try {
      const gameRef = doc(firestore, 'publicGames', inviteCode);
      const gameDoc = await getDoc(gameRef);
      
      if (gameDoc.exists()) {
        const gameData = gameDoc.data();
        if (gameData.questions && Array.isArray(gameData.questions)) {
          setQuestions(gameData.questions);
          console.log('Fetched questions:', gameData.questions);

            // Fetch images for questions
         //   await fetchImagesForQuestions(gameData.questions);
          // await updateDoc(gameRef, { gameStarted: true });
          await updateDoc(gameRef, { 
            gameStarted: true,
            // timeRemaining: timerEnabled ? timerDuration : null,
            isTimerRunning: true, // Add this line
            currentQuestionIndex: 0, // Add this line to ensure we start from the first question
      
          });
        
        
        } else {
          console.error('No questions found or invalid questions format');
        }
      } else {
        console.error('Game document not found');
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  
    setIsLoadingQuestions(false);
    console.log('handleStartGame finished');
    setIsTimerRunning(true);

  };

  const handleTimeUp = async () => {
    setAllPlayersAnswered(true);
    setIsTimerRunning(false);
    
    // Update the game document to stop the timer
    const gameRef = doc(firestore, 'publicGames', inviteCode);
    await updateDoc(gameRef, { isTimerRunning: false });
  };

  const handleNextQuestion = async () => {
  const gameRef = doc(firestore, 'publicGames', inviteCode);
  const playersRef = collection(gameRef, 'players');

  
  if (currentQuestionIndex < questions.length - 1) {
    const nextQuestionIndex = currentQuestionIndex + 1;


    // Reset the timer for the next question
    setCurrentTimerTime(timerDuration);
    setIsTimerRunning(true);

        // Update the game document
        await updateDoc(gameRef, {
          currentQuestionIndex: nextQuestionIndex,
          playersWhoAnswered: [],
        });
    

    // Update all players in the subcollection
    const playersSnapshot = await getDocs(playersRef);
    const updatePromises = playersSnapshot.docs.map(playerDoc => {
      return updateDoc(doc(playersRef, playerDoc.id), { 
        answer: null
      });
    });
    await Promise.all(updatePromises);

    // Update the game document
    await updateDoc(gameRef, {
      currentQuestionIndex: nextQuestionIndex,
      playersWhoAnswered: [],
      isTimerRunning: true, // Add this line to restart the timer
      currentTimerTime: timerDuration // Add this line

    });

    // Update local state
    setCurrentQuestionIndex(nextQuestionIndex);
    setPlayersWhoAnswered([]);
    setSelectedAnswer(null);
    setAllPlayersAnswered(false);

    // Fetch updated players
    const updatedPlayersSnapshot = await getDocs(playersRef);
    const updatedPlayers = updatedPlayersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setPlayers(updatedPlayers);
    setLeaderboard(updatedPlayers.sort((a, b) => b.score - a.score));
    setIsTimerRunning(true);

  } else {
    // Set game as finished
    await updateDoc(gameRef, { gameFinished: true });
    setGameFinished(true);
  }
};

if (showJoinGame) {
  return (
    <PartyGameContainer>
              <ReturnButton onClick={handleReturnClick}>&#8592; </ReturnButton>

      {/* <ReturnButton onClick={() => setShowJoinGame(false)}>&#8592; Back</ReturnButton> */}
      <h2>Join a Trivia Game</h2>
      <StyledInput
        type="text"
        placeholder="Enter invite code"
        value={inviteCode}
        onChange={(e) => setInviteCode(e.target.value)}
      />
      <StyledInput
        type="text"
        placeholder="Enter your nickname"
        value={nickname}
        onChange={(e) => setNickname(e.target.value)}
      />
      <GameButton onClick={handleJoinGameSubmit}>Join Game</GameButton>
    </PartyGameContainer>
  );
}

  if (showWelcomeScreen) {
    return (
      <PartyGameContainer>
        <ReturnButton onClick={handleReturnClick}>&#8592; </ReturnButton>
        {/* <h1>Welcome to the group trivia game 🎉</h1> */}
        {/* <img src={gametime} alt="Multiplayer game" style={{ width: '150px', paddingBottom: '30px' }} /> */}

        <img src="/supertutorlogo.png" alt="Supertutor" style={{ height: '100px', marginTop: '50px', marginRight: '10px' }} />
      <h2 style={{ 
               fontFamily: "'Poppins', sans-serif",
               fontStyle: 'normal',
               fontWeight: '700',
               //fontSize: '48px',
  textAlign: 'center', 
  fontSize: '1.4em', // Adjust this to make the text smaller
  maxWidth: '5500px', // Adjust this to control the width of the text block
  margin: '0 auto', // This centers the block horizontally
  marginBottom: '20px', // This adds a bottom margin. Change '20px' to the value you want.
  marginTop: '20px' // This adds a bottom margin. Change '20px' to the value you want.

}}>

Join the fun and learn together with our multiplayer games! </h2>

        {userSubscriptionPlan !== 'free' ? (
          <>


  {!showGameOptions ? (
    <>
      <GameButton onClick={handleCreateGameClick}>Start a game</GameButton>
      <GameButton onClick={handleJoinGameClick}>Join a game</GameButton>
    </>
  ) : (
    <>
      <ReturnButton onClick={() => setShowGameOptions(false)}>&#8592; Back</ReturnButton>
      <h2 style={{ 
               fontFamily: "'Poppins', sans-serif",
               fontStyle: 'normal',
               fontWeight: '700',
               //fontSize: '48px',
  textAlign: 'center', 
  fontSize: '1.4em', // Adjust this to make the text smaller
  maxWidth: '5500px', // Adjust this to control the width of the text block
  margin: '0 auto', // This centers the block horizontally
  marginBottom: '20px', // This adds a bottom margin. Change '20px' to the value you want.
  marginTop: '20px' // This adds a bottom margin. Change '20px' to the value you want.

}}>
   Choose a game type:</h2>
      <CreativeGameButton onClick={handleCreateGame}>Trivia Game</CreativeGameButton>
      <TriviaGameButton onClick={handleStartCreativeGame}>Imagination game</TriviaGameButton>
    </>
  )}
{/* 
<h2 style={{ 
               fontFamily: "'Poppins', sans-serif",
               fontStyle: 'normal',
               fontWeight: '700',
               //fontSize: '48px',
  textAlign: 'center', 
  fontSize: '1.4em', // Adjust this to make the text smaller
  maxWidth: '6000px', // Adjust this to control the width of the text block
  margin: '0 auto', // This centers the block horizontally
  marginBottom: '20px', // This adds a bottom margin. Change '20px' to the value you want.
  marginTop: '20px' // This adds a bottom margin. Change '20px' to the value you want.

}}>

Test your knowledge with the Trivia game:
</h2>
   <CreativeGameButton onClick={handleCreateGame}>Create The Trivia Game</CreativeGameButton>


<h2 style={{ 
               fontFamily: "'Poppins', sans-serif",
               fontStyle: 'normal',
               fontWeight: '700',
               //fontSize: '48px',
  textAlign: 'center', 
  fontSize: '1.4em', // Adjust this to make the text smaller
  maxWidth: '5500px', // Adjust this to control the width of the text block
  margin: '0 auto', // This centers the block horizontally
  marginBottom: '20px', // This adds a bottom margin. Change '20px' to the value you want.
  marginTop: '20px' // This adds a bottom margin. Change '20px' to the value you want.

}}>
 Get creative with the Imagination game:
</h2>
   <TriviaGameButton onClick={handleStartCreativeGame}>Create The Imagination game</TriviaGameButton>

<GameButton onClick={handleJoinGameClick}>Join a Game</GameButton> */}
{showGameButtons && (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <CreativeGameButton onClick={handleJoinGame}>Join Trivia Game</CreativeGameButton>
    <TriviaGameButton onClick={handleJoinCreativeGame}>Join Greatest Imagination Game</TriviaGameButton>
  </div>
)}

          </>
        ) : (
          <>
       {/* <GameButton onClick={handleJoinGame}>Join a Game</GameButton> */}
           
        <div> 
          <CreativeGameButton onClick={handleJoinGame}>Join Trivia Game</CreativeGameButton>
          <TriviaGameButton onClick={handleJoinCreativeGame}>Join Greatest Imagination Game</TriviaGameButton>
        </div> 
        {showGameTypeSelection && (
           <div> 
           <GameButton onClick={handleJoinGame}>Join Trivia Game</GameButton>
           <GameButton onClick={handleJoinCreativeGame}>Join Greatest Imagination Game</GameButton>
         </div> 
    )}
            <GameButton onClick={() => navigate('/')}>Upgrade to Create Your Own Game</GameButton>
          </>
        )}


      </PartyGameContainer>
    );
}

if (showJoinTrivia) {
  return (
    <PartyGameContainer>
      <ReturnButton onClick={() => setShowJoinTrivia(false)}>&#8592; Back</ReturnButton>
      <h2>Join a Trivia Game</h2>
      <StyledInput
        type="text"
        placeholder="Enter invite code"
        value={inviteCode}
        onChange={(e) => setInviteCode(e.target.value)}
      />
      <StyledInput
        type="text"
        placeholder="Enter your nickname"
        value={nickname}
        onChange={(e) => setNickname(e.target.value)}
      />
      <GameButton onClick={handleJoinGameSubmit}>Join Game</GameButton>
    </PartyGameContainer>
  );
}

if (showJoinCreative) {
  return (
    <PartyGameContainer>
      <ReturnButton onClick={() => setShowJoinCreative(false)}>&#8592; Back</ReturnButton>
      <h2>Join a Creative Game</h2>
      <StyledInput
        type="text"
        placeholder="Enter invite code"
        value={inviteCode}
        onChange={(e) => setInviteCode(e.target.value)}
      />
      <StyledInput
        type="text"
        placeholder="Enter your nickname"
        value={nickname}
        onChange={(e) => setNickname(e.target.value)}
      />
      <GameButton onClick={handleJoinCreativeGameSubmit}>Join Game</GameButton>
    </PartyGameContainer>
  );
}

// if (showCreativeGame) {
//   return <CreativeGame />;
// }

if (gameFinished) {
  return (
    <FinalSummary 
      leaderboard={leaderboard} 
      allQuestionsAndAnswers={allQuestionsAndAnswers}
      isHost={isHost}
      userSubscriptionPlan={userSubscriptionPlan}
      handleCreateNewGame={handleCreateNewGame}
      handleJoinNewGame={handleJoinNewGame}
      handleJoinSuperTutor={handleJoinSuperTutor}
    />
  );
}

  if (showCreateGame) {
    return (
      <PartyGameContainer>
                <ReturnButton onClick={handleReturnClick}>&#8592; </ReturnButton>

      {/* <ReturnButton onClick={() => setShowCreateGame(false)}>&#8592; Back</ReturnButton> */}
      <h2 style={{ 
               fontFamily: "'Poppins', sans-serif",
               fontStyle: 'normal',
               fontWeight: '700',
               //fontSize: '48px',
  textAlign: 'center', 
  fontSize: '1.4em', // Adjust this to make the text smaller
  maxWidth: '5500px', // Adjust this to control the width of the text block
  margin: '0 auto', // This centers the block horizontally
  marginBottom: '20px', // This adds a bottom margin. Change '20px' to the value you want.
  marginTop: '20px' // This adds a bottom margin. Change '20px' to the value you want.

}}>Create a Trivia Game</h2>
      <StyledInput
        type="text"
        value={gameTopic}
        onChange={(e) => setGameTopic(e.target.value)}
        placeholder="Enter game topic"
      />
<div style={{ width: '100%', minWidth: '800px', overflow: 'hidden' }}>
          {/* <GlidingTopicsContainer> */}
          <GlidingTopics topics={SampleTopics} onTopicSelect={handleTopicSelect} />
        {/* </GlidingTopicsContainer> */}
      </div>
    
      <FormGroup>
      <Label style={{ paddingTop: '10px' }}>Difficulty Level:</Label>
              <Select value={gameDifficulty} onChange={(e) => setGameDifficulty(e.target.value)}>
          <option value="Beginner">Beginner</option>
          <option value="Challenging">Challenging</option>
          <option value="Ridiculously Difficult">Ridiculously Difficult</option>
        </Select>
      </FormGroup>
    
      <FormGroup>
        <Label>Number of Questions:</Label>
        <NumberInput
          type="number"
          min={2}
          max={10}
          value={numQuestions}
          onChange={(e) => setNumQuestions(e.target.value)}
        />
      </FormGroup>

      <FormGroup>
  <Label>Enable Timer:</Label>
  <input
    type="checkbox"
    checked={timerEnabled}
    onChange={(e) => setTimerEnabled(e.target.checked)}
  />
</FormGroup>
{timerEnabled && (
  <FormGroup>
    <Label>Timer Duration (seconds):</Label>
    <NumberInput
      type="number"
      min={5}
      max={60}
      value={timerDuration}
      onChange={(e) => setTimerDuration(parseInt(e.target.value))}
    />
  </FormGroup>
)}
    
      <GameButton onClick={handleGenerateGame} disabled={generatingGame}>
        {generatingGame ? (
          <>
            {/* <SpinnerIcon /> */}
            <LoadingSpinner />

            Generating Game...
          </>
        ) : (
          'Create Questions with AI'
        )}
      </GameButton>
      <GameButton onClick={handleManualCreation}>
        Create Questions Manually
      </GameButton>
    </PartyGameContainer>
    );
  }
  
  if (showManualCreation) {
    return (
      <PartyGameContainer>
        <ReturnButton onClick={() => setShowManualCreation(false)}>&#8592; Back</ReturnButton>
        <h2>Create Game Manually</h2>
        {manualQuestions.map((question, index) => (
  <div key={index}>
    <h3>
      Question {index + 1}
      <RemoveQuestionButton onClick={() => handleRemoveQuestion(index)}>X</RemoveQuestionButton>
    </h3>
    <input
      type="text"
      value={question.question}
      onChange={(e) => handleQuestionChange(index, 'question', e.target.value)}
      placeholder="Enter question"
    />
    {question.choices.map((choice, choiceIndex) => (
      <input
        key={choiceIndex}
        type="text"
        value={choice}
        onChange={(e) => handleQuestionChange(index, 'choice', { index: choiceIndex, choice: e.target.value })}
        placeholder={`Choice ${choiceIndex + 1}`}
      />
    ))}
    <select
      value={question.correctAnswer}
      onChange={(e) => handleQuestionChange(index, 'correctAnswer', e.target.value)}
    >
      <option value="">Select correct answer</option>
      {[1, 2, 3, 4].map((num) => (
        <option key={num} value={num}>
          Answer {num}
        </option>
      ))}
    </select>
  </div>
))}
        <GameButton onClick={handleAddQuestion}>Add Question</GameButton>
        <GameButton onClick={handleManualGameCreation}>Create Game</GameButton>
      </PartyGameContainer>
    );
  }

  

  if (showJoinGame) {
    return (
      <PartyGameContainer>
        <ReturnButton onClick={() => {
          setShowJoinGame(false);
          setShowGameTypeSelection(true);
        }}>&#8592; Back</ReturnButton>
        <h2>Join a Trivia Game</h2>
        <StyledInput
          type="text"
          placeholder="Enter invite code"
          value={inviteCode}
          onChange={(e) => setInviteCode(e.target.value)}
        />
        <StyledInput
          type="text"
          placeholder="Enter your nickname"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
        />
        <GameButton onClick={handleJoinGameSubmit}>Join Game</GameButton>
      </PartyGameContainer>
    );
  }



  if (!gameState && !isHost) {
    return (
      <PartyGameContainer>
        <ReturnButton onClick={handleReturnClick}>&#8592; Return</ReturnButton>
        <h2>Loading game state...</h2>
      </PartyGameContainer>
    );
  }


// Modify the waiting room render to include the loading state for the Start Game button:
if (waitingForPlayers) {
  return (
    <PartyGameContainer>
      <ReturnButton onClick={handleReturnClick}>&#8592; Return</ReturnButton>
      <h2>Invite players to join the game or play with yourself</h2>
      <ShareSection>
        <h1>Invite Code: {inviteCode}</h1>
        <p>Guests can join at supertutor.me/partygame</p>
        <QRCode value={`https://supertutor.me/partygame?code=${inviteCode}`} />
        <ShareButton onClick={() => {
          const shareText = `Join my Supertutor Trivia Game! Go to https://supertutor.me/partygame and use code ${inviteCode}`;
          
          copyToClipboard(shareText)
            .then(() => {
              if (navigator.share) {
                navigator.share({
                  title: 'Join my SuperTutorAI Party Game',
                  text: shareText,
                  url: 'https://supertutor.me/partygame'
                });
              } else {
                // Fallback for browsers that don't support Web Share API
                alert(`Text copied to clipboard!\n\nShare this text:\n\n${shareText}`);
              }
            })
            .catch(err => {
              console.error('Failed to copy text: ', err);
              alert('Failed to copy text. Please copy it manually:\n\n' + shareText);
            });
        }}>
          Share Invite
        </ShareButton>
      </ShareSection>
      <h3>Joined Players:</h3>
      <ul>
        {players.map((player, index) => (
          <li key={index}>{player.nickname} {player.isHost ? '⭐️' : ''}</li>
        ))}
      </ul>
      {isHost && (
        <GameButton onClick={handleStartGame} disabled={generatingGame}>
          {generatingGame ? (
            <>
              Generating Game...
              <InlineSpinner />
            </>
          ) : (
            'Start Game'
          )}
        </GameButton>
      )}
    </PartyGameContainer>
  );
}

  if (currentQuestionIndex >= questions.length) {
    return (
      <PartyGameContainer>
        <ReturnButton onClick={handleReturnClick}>&#8592; Return</ReturnButton>
        <h2>Game Over</h2>
        <h3>Leaderboard:</h3>
        <ul>
          {leaderboard.sort((a, b) => b.score - a.score).map((player, index) => (
            <li key={index}>
              {player.nickname} - Score: {player.score}
            </li>
          ))}
        </ul>
      </PartyGameContainer>
    );
  }

  // const allPlayersAnswered = players.every((player) => player.answer);
  return (
    <PartyGameContainer>
      <ReturnButton onClick={handleReturnClick}>&#8592; Return</ReturnButton>
      {/* <h1>Trivia Game</h1> */}
      <img src={gametime} alt="Multiplayer game" style={{ width: '70px' }} /> 

      {/* {isLoadingQuestions ? (
  <p>Loading questions...</p>
) : questions && questions.length > 0 && currentQuestionIndex < questions.length ? (
  <> */}
{isLoadingQuestions ? (
  <p>Loading questions...</p>
) : questions && questions.length > 0 && currentQuestionIndex < questions.length ? (
  <>

<QuestionText>Current Question: {questions[currentQuestionIndex].question}</QuestionText>

{timerEnabled && (
  <Timer
    key={currentQuestionIndex} // Add this line to force re-render on question change
    duration={currentTimerTime}
    onTimeUp={handleTimeUp}
    isRunning={isTimerRunning}
  />
)}
    <ul>
    {questions[currentQuestionIndex].choices.map((choice, index) => (
      <AnswerButton
  key={index}
  onClick={() => !playersWhoAnswered.includes(nickname) && handleAnswerSelection(choice)}
  disabled={playersWhoAnswered.includes(nickname)}
  style={{
    backgroundColor: playersWhoAnswered.includes(nickname) || selectedAnswer === choice
      ? choice === questions[currentQuestionIndex].correctAnswer
        ? '#4CAF50'
        : '#f44336'
      : '#4CAF50'
  }}
>
  {choice}
  {(playersWhoAnswered.includes(nickname) || selectedAnswer === choice) && 
   choice === questions[currentQuestionIndex].correctAnswer && ' ✓'}
</AnswerButton>
))}
    </ul>
  </>
) : (
  <p>No questions available or game over.</p>
)}

{isHost && <GameButton onClick={handleNextQuestion}>Next Question</GameButton>}


{playersWhoAnswered.length > 0 && (
  <div>
    <h3>Players who answered:</h3>
    <ul>
    {playersWhoAnswered.map((playerNickname, index) => (
  <li key={index}>{playerNickname}</li>
))}
    </ul>
  </div>
)}



{selectedAnswer && (
  <p>Your selected answer: {selectedAnswer}</p>
)}

{/* {(allPlayersAnswered || (isHost && playersWhoAnswered.includes(nickname))) && ( */}
{(allPlayersAnswered || !isTimerRunning) && (

      <>
<CorrectAnswerText>Correct Answer: {questions[currentQuestionIndex].correctAnswer}</CorrectAnswerText>
<ExplanationText>Explanation: {questions[currentQuestionIndex].explanation}</ExplanationText>
<LeaderboardContainer>
  <LeaderboardTitle>Leaderboard</LeaderboardTitle>
  <LeaderboardList>
    {players.sort((a, b) => b.score - a.score).map((player, index) => (
      <LeaderboardItem key={index}>
        <div>
          <PlayerRank>{index + 1}.</PlayerRank>
          <PlayerName>{player.nickname}</PlayerName>
        </div>
        <PlayerScore>{player.score}</PlayerScore>
      </LeaderboardItem>
    ))}
  </LeaderboardList>
</LeaderboardContainer>
        {/* {isHost && <GameButton onClick={handleNextQuestion}>Next Question</GameButton>} */}
      </>
    )}
  {showConfetti && <div id="confetti-container" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none', zIndex: 9999 }} />}

    </PartyGameContainer>
  );
};

export default PartyGame;